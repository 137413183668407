@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: var(--font-poppins), system-ui, sans-serif;

		.landing-app {
			h1,
			h2,
			h3 {
				font-family: var(--font-lora), var(--font-poppins), system-ui, sans-serif;
			}
		}
	}

	:root {
		--background: 0 0% 100%;
		--foreground: 240 10% 3.9%;
		--card: 0 0% 100%;
		--card-foreground: 240 10% 3.9%;
		--popover: 0 0% 100%;
		--popover-foreground: 240 10% 3.9%;
		--primary: 240 5.9% 10%;
		--primary-foreground: 0 0% 98%;
		--secondary: 240 4.8% 95.9%;
		--secondary-foreground: 240 5.9% 10%;
		--muted: 240 4.8% 95.9%;
		--muted-foreground: 240 3.8% 46.1%;
		--accent: 240 4.8% 95.9%;
		--accent-foreground: 240 5.9% 10%;
		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 0 0% 98%;
		--border: 240 5.9% 90%;
		--input: 240 5.9% 90%;
		--ring: 240 10% 3.9%;
		--chart-1: 12 76% 61%;
		--chart-2: 173 58% 39%;
		--chart-3: 197 37% 24%;
		--chart-4: 43 74% 66%;
		--chart-5: 27 87% 67%;
		--radius: 0.5rem;
		--sidebar-background: 0 0% 100%;
		--sidebar-foreground: 240 5.3% 26.1%;
		--sidebar-primary: 240 5.9% 10%;
		--sidebar-primary-foreground: 0 0% 98%;
		--sidebar-accent: 216 80% 98%;
		--sidebar-accent-foreground: 240 5.9% 10%;
		--sidebar-border: 220 13% 91%;
		--sidebar-ring: 217.2 91.2% 59.8%;
	}

	* {
		@apply border-border;
	}

	body {
		@apply bg-background text-foreground;
	}
}

.outline input:focus-within ~ label,
.outline input:not(:placeholder-shown) ~ label {
	@apply z-0 ml-3 -translate-y-4 scale-75 transform px-1 py-0;
}

input {
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		appearance: none;
		margin: 0;
	}

	&[type='radio'] {
		appearance: none;
	}

	&[type='number'] {
		appearance: textfield;
	}

	&[type='date'] {
		position: relative;

		&::-webkit-calendar-picker-indicator {
			color: transparent;
			background: none;
			z-index: 1;
		}

		&::before {
			content: '';
			background-image: url('../public/icons/calendar.svg');
			width: 24px;
			height: 24px;
			position: absolute;
			right: 1rem;
		}
	}
}

select {
	appearance: none;

	&:not([size]) {
		background-image: url('../public/icons/chevron-down.svg');
		background-position: right 1rem center;
		background-repeat: no-repeat;
		background-size: 1.5rem 1.5rem;
		padding-right: 2.5rem;
		print-color-adjust: exact;
	}
}

:root {
	--lokappy-modal-duration: 200ms;
}

.ReactModal__Overlay {
	opacity: 0;
	transition: opacity var(--lokappy-modal-duration) ease-in-out;
}

.ReactModal__Overlay--after-open {
	opacity: 1;
}

.ReactModal__Overlay--before-close {
	opacity: 0;
}

.ReactModal__Content {
	transform: scale(0) translate(-50%, -50%);
	transform-origin: 0 0;
	transition: transform var(--lokappy-modal-duration) ease-in-out;
}

.ReactModal__Content--after-open {
	transform: scale(1) translate(-50%, -50%);
}

.ReactModal__Content--before-close {
	transform: scale(0) translate(-50%, -50%);
}

/* TOASTIFY STYLES */
.Toastify__toast {
	font-family: Poppins, system-ui, sans-serif !important;
	@apply !flex items-center !rounded-xl !bg-black-text !text-white !shadow-[0_4px_20px_0_rgba(25,22,47,0.20)];
}

.Toastify__progress-bar {
	@apply !bg-valid-green;
}

.Toastify__toast-body {
	@apply !m-0 !p-1;
}

.Toastify__close-button {
	@apply !self-auto !text-white !opacity-100;

	& > svg {
		@apply !h-5 !w-5;
	}
}

.Toastify__toast-icon {
	@apply !h-9 !w-9;
}

/* Bottom sheet */
.react-modal-sheet-backdrop {
	background-color: rgb(25 22 47 / 75%) !important;
	backdrop-filter: blur(5px) !important;
}

.react-modal-sheet-container {
	border-radius: 30px 30px 0 0 !important;
	background-color: white !important;
}

.react-modal-sheet-header {
	/* custom styles */
}

.react-modal-sheet-drag-indicator {
	border-radius: 20px !important;
	background: #d4e3f7 !important;
	height: 4px !important;
	width: 35px !important;
}

.react-modal-sheet-content {
	padding: 1.875rem 0.75rem 0.75rem !important;
}
